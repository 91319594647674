<template>
  <div class="py-4 container-fluid dashboard2 ">
    <div class="row mb-4">
      <div class="col-lg-12 position-relative z-index-2">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-6 one">
             <router-link to='/events'>
            <mini-statistics-card
              :title="{text:'الحفلات', value: 7 }"
              detail=""
              :icon="{
                name: 'book',
                color: 'text-white',
                background: 'dark',
              }"
            />
             </router-link>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6 mt-lg-0 mt-4 four">
             <router-link to='/users'>
            <mini-statistics-card
              :title="{text: 'المستخدمين', value: 4  }"
              detail=""
              :icon="{
                name: 'leaderboard',
                color: 'text-white',
                background: 'info',
              }"
            />
             </router-link>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="row mb-4" >
      <div class="col">
         <div class="col-lg-11 col-md-6 mt-4 chart-col">
            <chart-holder-card
              title="الحضور اليوم"
              subtitle="(<span class='font-weight-bolder'>+15%</span>)   نسبة الحضور"
              update="تم التحديث منذ 4 دقائق"
              color="success"
            >
              <reports-line-chart
                :chart="{
                  labels: [
                    'Apr',
                    'May',
                    'Jun',
                    'Jul',
                    'Aug',
                    'Sep',
                    'Oct',
                    'Nov',
                    'Dec',
                  ],
                  datasets: {
                    label: 'Mobile apps',
                    data: [50, 40, 300, 320, 500, 350, 200, 230, 500],
                  },
                }"
              />
            </chart-holder-card>
          </div>
      </div>
    </div> -->

  </div>
</template>
<script>
// import ChartHolderCard from './components/ChartHolderCard.vue'
// import ReportsLineChart from '@/examples/Charts/ReportsLineChart.vue'
import MiniStatisticsCard from './components/MiniStatisticsCard.vue'
import logoAtlassian from '@/assets/img/small-logos/logo-atlassian.svg'
import logoSlack from '@/assets/img/small-logos/logo-slack.svg'
import logoSpotify from '@/assets/img/small-logos/logo-spotify.svg'
import logoJira from '@/assets/img/small-logos/logo-jira.svg'
import logoInvision from '@/assets/img/small-logos/logo-invision.svg'
const today = new Date()
const currentDate = today.getDate()
const currentMonth = today.getMonth() + 1 // Months are zero-based
const currentYear = today.getFullYear()

// Format the date as desired
const formattedDate = currentYear + '-' + currentMonth + '-' + currentDate
export default {
  name: 'dashboard-default',
  data () {
    return {
      logoAtlassian,
      logoSlack,
      logoSpotify,
      logoJira,
      logoInvision,
      statistics: {},
      activeLectures: [],
      todayDate: formattedDate,
      centerType: ''
    }
  },
  components: {
    MiniStatisticsCard
    // ChartHolderCard,
    // ReportsLineChart
  },
  // created () {
  //   this.centerType = localStorage.centerType
  //   this.$http.get(`${this.$hostUrl}statistics`).then(response => {
  //     this.statistics = response.data.data
  //   })
  //   // api/dashboard/branches/lectures/today
  //   this.$http.get(`${this.$hostUrl}branches/lectures/today`).then(response => {
  //     this.activeLectures = response.data.data
  //   })
  // },
  methods: {
    getDate (startDate) {
      let start = startDate.substr(11, 5)
      // Check correct time format and split into components
      start = start.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [start]

      if (start.length > 1) { // If time format correct
        start = start.slice(1) // Remove full string match value
        start[5] = +start[0] < 12 ? ' AM ' : ' PM ' // Set AM/PM
        start[0] = +start[0] % 12 || 12 // Adjust hours
      }
      return start.join('') // return adjusted time or original strin
    //   start = (startHours >= 12) ? start + ' ' + 'PM' : start + ' ' + 'AM'
    //   return start
    }
  },
  mounted () {
    if (!localStorage.token) {
      this.$router.push({ name: 'SignIn' })
    }
  }
}
</script>
<style scoped>
.margin-top{
  margin-top: 45px;
}
.no-lecture{
  font-weight: bold;
}
.dir{
  direction: ltr !important
}
.fa-calendar{
  padding-right: 2px;
}
</style>

import { h, resolveComponent } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'
import Dashboard from '../views/Dashboard'

// users
import Users from '../views/Users/Index.vue'
import AddUser from '../views/Users/Create.vue'
import EditUser from '../views/Users/Edit.vue'
import ShowUser from '../views/Users/Show.vue'
import UserCollections from '../views/UserCollections.vue'

import RTL from '../views/Rtl.vue'

import SignIn from '../views/SignIn.vue'
function generateRoutes () {
  const routes = [
    {
      path: '/',
      name: '/',
      redirect: '/dashboard'
    },
    {
      path: '/dashboard',
      name: 'الرئيسية',
      component: Dashboard,
      meta: {
        title: 'الرئيسية',
        page: 'الرئيسية'
      }
    },
    {
      path: '/events',
      component: {
        render () {
          return h(resolveComponent('router-view'))
        }
      },
      children: [
        {
          path: '',
          name: 'الحفلات',
          component: () => import('../views/Events/Index.vue'),
          meta: {
            title: ' الحفلات'
          }
        },
        {
          path: ':id/edit',
          name: ' تعديل بيانات الحفلة',
          component: () => import('../views/Events/Edit.vue'),
          meta: {
            title: ' تعديل بيانات الحفلة'
          }
        },
        {
          path: 'create',
          name: 'إضافة حفلة',
          component: () => import('../views/Events/Create.vue'),
          meta: {
            title: 'إضافة حفلة'
          }
        },
        {
          path: ':id/show',
          name: ' عرض بيانات الحفلة',
          component: () => import('../views/Events/Show.vue'),
          meta: {
            title: ' عرض بيانات الحفلة'
          }
        },
        {
          path: ':id/show-report',
          name: ' عرض تقرير الحفلة',
          component: () => import('../views/Events/ShowReport.vue'),
          meta: {
            title: ' عرض تقرير الحفلة'
          }
        }
      ]
    },
    // users
    {
      path: '/users',
      name: '  كل المستخدمين ',
      component: {
        render () {
          return h(resolveComponent('router-view'))
        }

      },
      children: [
        {
          path: '',
          name: '  كل المستخدمين ',
          component: Users,
          meta: {
            title: 'المستخدمين'
          }
        },
        {
          path: 'create',
          name: ' إضافة مستخدم ',
          component: AddUser,
          meta: {
            title: 'إضافة مستخدم'
          }
        },
        {
          path: ':id/edit',
          name: ' تعديل المستخدم ',
          component: EditUser,
          meta: {
            title: 'تعديل مستخدم'
          }
        },
        {
          path: ':id/show',
          name: ' عرض المستخدم ',
          component: ShowUser,
          meta: {
            title: 'عرض المستخدم'
          }
        }
      ]
    },
    // clients
    {
      path: '/clients',
      component: {
        render () {
          return h(resolveComponent('router-view'))
        }
      },
      children: [
        {
          path: '',
          name: 'العملاء',
          component: () => import('../views/Clients/Index.vue'),
          meta: {
            title: ' العملاء'
          }
        },
        {
          path: ':id/edit',
          name: ' تعديل بيانات العميل',
          component: () => import('../views/Clients/Edit.vue'),
          meta: {
            title: ' تعديل بيانات العميل'
          }
        },
        {
          path: 'create',
          name: 'إضافة عميل',
          component: () => import('../views/Clients/Create.vue'),
          meta: {
            title: 'إضافة عميل'
          }
        },
        {
          path: ':id/show',
          name: ' عرض بيانات العميل',
          component: () => import('../views/Clients/Show.vue'),
          meta: {
            title: ' عرض بيانات العميل'
          }
        }
      ]
    },
    // companions
    {
      path: '/companions',
      component: {
        render () {
          return h(resolveComponent('router-view'))
        }
      },
      children: [
        {
          path: '',
          name: 'المرافقين',
          component: () => import('../views/Companions/Index.vue'),
          meta: {
            title: ' المرافقين'
          }
        },
        {
          path: ':id/edit',
          name: ' تعديل بيانات المرافق',
          component: () => import('../views/Companions/Edit.vue'),
          meta: {
            title: ' تعديل بيانات المرافق'
          }
        },
        {
          path: 'create',
          name: 'إضافة مرافق',
          component: () => import('../views/Companions/Create.vue'),
          meta: {
            title: 'إضافة مرافق'
          }
        },
        {
          path: ':id/show',
          name: ' عرض بيانات المرافق',
          component: () => import('../views/Companions/Show.vue'),
          meta: {
            title: ' عرض بيانات المرافق'
          }
        }
      ]
    },
    // client payments
    {
      path: '/clients-payments',
      component: {
        render () {
          return h(resolveComponent('router-view'))
        }
      },
      children: [
        {
          path: '',
          name: 'دفعيات العملاء',
          component: () => import('../views/ClientsPayments/Index.vue'),
          meta: {
            title: ' دفعيات العملاء'
          }
        },
        {
          path: ':id/edit',
          name: ' تعديل دفعيات العملاء',
          component: () => import('../views/ClientsPayments/Edit.vue'),
          meta: {
            title: ' تعديل دفعيات العملاء'
          }
        },
        {
          path: 'create',
          name: 'إضافة دفعية',
          component: () => import('../views/ClientsPayments/Create.vue'),
          meta: {
            title: 'إضافة دفعية'
          }
        },
        {
          path: ':id/show',
          name: ' عرض دفعيات',
          component: () => import('../views/ClientsPayments/Show.vue'),
          meta: {
            title: ' عرض  دفعيات العملاء'
          }
        }
      ]
    },
    // companion payments
    {
      path: '/companions-payments',
      component: {
        render () {
          return h(resolveComponent('router-view'))
        }
      },
      children: [
        {
          path: '',
          name: 'دفعيات المرافقين',
          component: () => import('../views/CompanionsPayments/Index.vue'),
          meta: {
            title: ' دفعيات المرافقين'
          }
        },
        {
          path: ':id/edit',
          name: ' تعديل دفعيات المرافق',
          component: () => import('../views/CompanionsPayments/Edit.vue'),
          meta: {
            title: ' تعديل دفعيات المرافق'
          }
        },
        {
          path: 'create',
          name: 'إضافة دفعية للمرافق ',
          component: () => import('../views/CompanionsPayments/Create.vue'),
          meta: {
            title: 'إضافة دفعية للمرافق'
          }
        },
        {
          path: ':id/show',
          name: ' عرض دفعيات المرافق',
          component: () => import('../views/CompanionsPayments/Show.vue'),
          meta: {
            title: ' عرض  دفعيات المرافق'
          }
        }
      ]
    },
    {
      path: '/user-collections',
      name: 'تحصيلات المستخدم',
      component: UserCollections,
      meta: {
        title: ' تحصيلات المستخدم'
      }
    },

    {
      path: '/rtl-page',
      name: 'RTL',
      component: RTL
    },
    {
      path: '/login',
      name: 'SignIn',
      component: SignIn,
      meta: {
        title: ' تسجيل الدخول'
      }
    }
  ]
  return routes
}

const router = createRouter({
  mode: 'hash',
  history: createWebHashHistory(),
  routes: generateRoutes(),
  linkActiveClass: 'active'
})

export default router

<template>
  <div class="container-fluid py-4 add-student grade-detail">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3">
              <div class="row">
                <div class="col-lg-6">
                  <h6 class="text-white text-capitalize ps-3">   التحصيلات  اليومية للمستخدم   : {{userReceipts.name}}</h6>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
                <div class=" px-0 pb-2">
                  <!-- exceptional attendance row -->
                  <div class="row align-center" style=" margin-top: 40px;direction:rtl" id="print-report">
                    <div class="text-center col-lg-12" style="direction:rtl">
                      <table class=" b-block  table " style="direction:rtl">
                        <thead>
                          <tr>
                            <th></th>
                            <th>عدد</th>
                            <th>الإجمالي</th>
                            <th>عدد اللاغي</th>
                            <th>إجمالي اللاغي</th>
                          </tr>
                          <tr>
                            <th> الحضور </th>
                            <td class="hr-cell">{{userReceipts.attendancePaymentCount}}</td>
                            <td class="hr-cell">{{$filters.number(userReceipts.attendancePayment)}}</td>
                            <td class="hr-cell">{{userReceipts.attendancerefundsCount}}</td>
                            <td class="hr-cell">{{$filters.number(userReceipts.attendancerefund)}}</td>
                          </tr>
                          <tr>
                            <th> الحضور الإستثنائي </th>
                            <td class="hr-cell">{{userReceipts.exceptionalPaymentsCount}}</td>
                            <td class="hr-cell">{{$filters.number(userReceipts.exceptionalPayments)}}</td>
                            <td class="hr-cell">{{userReceipts.exceptionalRefundsCount}}</td>
                            <td class="hr-cell">{{$filters.number(userReceipts.exceptionalRefunds)}}</td>
                          </tr>
                          <tr>
                            <th> تحصيل الدورات </th>
                            <td class="hr-cell">{{userReceipts.coursesePaymentCount}}</td>
                            <td class="hr-cell">{{$filters.number(userReceipts.coursesePayment)}}</td>
                            <td class="hr-cell">{{userReceipts.courseserefundCount}}</td>
                            <td class="hr-cell">{{$filters.number(userReceipts.courseserefund)}}</td>
                          </tr>
                          <tr>
                            <th> المحفظة </th>
                            <td class="hr-cell">{{$filters.number(userReceipts.walletPayment)}}</td>
                            <td class="hr-cell">{{$filters.number(userReceipts.walletPayment)}}</td>
                            <td class="hr-cell">-</td>
                            <td class="hr-cell">-</td>
                          </tr>
                          <tr class="back-color">
                            <th style="color:#222; font-size:16px !important"> الإجمالي </th>
                            <td class="hr-cell">{{userReceipts.countPayments}}</td>
                            <td class="hr-cell">{{$filters.number(userReceipts.totalPayments)}}</td>
                            <td class="hr-cell">{{userReceipts.countrefunds}}</td>
                            <td class="hr-cell">{{$filters.number(userReceipts.totalRefunds)}}</td>
                          </tr>
                        </thead>
                      </table>
                    </div>
                  </div>
                </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'UserCollections',
  data () {
    return {
      userReceipts: {},
      totalUsers: [],
      offerPayments: 0,
      coursePayments: 0,
      totalCourseRefunds: 0,
      totalOfferRefunds: 0,
      attendancePayment: 0,
      totalAttendanceRefunds: 0,
      totalReceived: 0,
      totalRefunds: 0,
      detailsPayments: '',
      detailsRefunds: '',
      totaldetailRefunds: '',
      total: 0,
      errors: []
    }
  },
  created () {
    this.$http.get(`${this.$hostUrl}report/user/payments`).then(response => {
      this.userReceipts = response.data.data[0]
    })
  }
}

</script>
<style>
#collection-date{
  font-weight: 800 !important;
  font-size: 18px !important;
}
.table > :not(:first-child) {
    border-bottom: 1px solid #f0f2f5 !important;
}
.width{
  width:100%;
}
.online{
  border:1px solid #ddd
}
.times{
  margin-top: 10px !important;
}
.times h5{
  font-size:16px !important
}
.times .red{
  position: relative !important;
}
.filter-text{
  font-weight: 700;
    color: #dfad07;
    font-size: 15px !important;
}
.tab2{
  margin-top: 27px !important;
    margin-bottom: 41px !important;
    border-bottom: 1px solid #ddd;
    padding-bottom: 26px !important;
}
.list2{
      width: 31% !important;
    margin-right: 10px !important;
    margin-bottom: 20px !important
}
.hr-cell{
  background: #fff !important;
    border: 1px solid #ccc !important;
    color:#222 !important
}

</style>

<template>
  <div
    class="input-group"
    :class="`input-group-${variant} ${getStatus(error, success)}`"
  >
    <label :class="variant === 'static' ? '' : 'form-label'">{{ label }}</label>
    <input
      :id="id"
      :type="type"
      class="form-control inp"
      :class="getClasses(size)"
      :name="name"
      :placeholder="placeholder"
      :required='required'
      :disabled="disabled"
      :readonly ="readonly"
      v-model="content"
    />
  </div>
</template>

<script>
import setMaterialInput from '@/assets/js/material-input.js'

export default {
  name: 'VmdInput',
  props: {
    modelValue: String,
    variant: {
      type: String,
      default: 'outline'
    },
    label: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'default'
    },
    success: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      required: true
    },
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  mounted () {
    setMaterialInput()
  },
  computed: {
    content: {
      get () {
        return this.modelValue
      },
      set (val) {
        this.$emit('update:modelValue', val)
      }
    }
  },
  methods: {
    // handleInput (e) {
    //   this.$emit('update:modelValue', this.content)
    //   console.log('input content', this.content)
    // },
    getClasses: (size) => {
      const sizeValue = size ? `form-control-${size}` : null

      return sizeValue
    },
    getStatus: (error, success) => {
      let isValidValue

      if (success) {
        isValidValue = 'is-valid'
      } else if (error) {
        isValidValue = 'is-invalid'
      } else {
        isValidValue = null
      }

      return isValidValue
    }
  }
}
</script>

<template>
  <div
    class="page-header  login-area align-items-start min-vh-100"
    style="
      background-image: url('https://images.unsplash.com/photo-1492684223066-81342ee5ff30?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8ZXZlbnR8ZW58MHx8MHx8fDA%3D')
    "
  >
    <span class="mask bg-gradient-dark opacity-6"></span>
    <div class="container my-auto">
      <div class="row">
        <div class="col-lg-4 col-md-8 col-12 mx-auto">
          <div class="card z-index-0 fadeIn3 fadeInBottom">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="bg-gradient-success shadow-success border-radius-lg py-3 pe-1"
              >
               <h4 class="text-white font-weight-bolder text-center mt-2 mb-0">
                  Th-Event
                </h4>
                <h4 class="text-white font-weight-bolder text-center mt-2 mb-0">
                  تسجيل الدخول
                </h4>
              </div>
            </div>
            <div class="card-body">
              <form @submit.prevent="UserLogin" role="form" class="text-start mt-3">
                <div class="mb-3">
                  <vmd-input
                    id="email"
                    type="email"
                    v-model='user.email'
                    :value='user.email'
                    label="البريد الإلكتروني"
                    name="البريد الإلكتروني"
                  />
                </div>
                <div class="mb-3">
                  <vmd-input
                    id="password"
                    type="password"
                    label="كلمة المرور"
                    name="كلمة المرور"
                     v-model='user.password'
                     :value="user.password"
                  />
                </div>
                <div class="text-center">
                  <vmd-button
                    class="my-4 mb-2"
                    variant="gradient"
                    color="success"
                    fullWidth
                    >تسجيل الدخول </vmd-button
                  >
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VmdInput from '@/components/VmdInput.vue'
import VmdButton from '@/components/VmdButton.vue'
import { mapMutations } from 'vuex'
import swal from 'sweetalert'

export default {
  name: 'sign-in',
  components: {
    VmdInput,
    VmdButton
  },
  data () {
    return {
      user: {}
    }
  },
  created () {
    console.log(this.$hostUrl, 'url')
  },
  beforeMount () {
    this.toggleEveryDisplay()
    this.toggleHideConfig()
  },
  beforeUnmount () {
    this.toggleEveryDisplay()
    this.toggleHideConfig()
  },
  methods: {
    ...mapMutations(['toggleEveryDisplay', 'toggleHideConfig']),
    async UserLogin (e) {
      e.preventDefault()
      const formData = new FormData()
      formData.append('email', this.user.email)
      formData.append('password', this.user.password)

      try {
        const response = await this.$http.post(`${this.$hostUrl}login`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Access-Control-Allow-Origin': '*'
          }
        })

        if (!response.data.error) {
          localStorage.token = response.data.data.token
          localStorage.userName = response.data.data.user.name
          await this.$router.replace({ path: '/' })
        } else {
          swal({ title: response.data.message, buttons: 'تم' })
        }
      } catch (error) {
        if (Object.prototype.hasOwnProperty.call(error, 'response')) {
          const data = (error.response || {}).data
          if (data.error) {
            swal({ title: data.message, buttons: 'تم' })
          } else {
            swal({
              title: 'حدث خطـأ اثناء معالجة الطلب الرجاء المحاولة لاحقا',
              buttons: 'تم'
            })
          }
        } else {
          swal({
            title: 'حدث خطـأ اثناء معالجة الطلب الرجاء المحاولة لاحقا',
            buttons: 'تم'
          })
        }
      }
    }
  }
}
</script>
<style>
.login-area .card{
      background: transparent;
    border: 2px solid #dddd;
}

</style>

import { createStore } from 'vuex'
import { useRouter } from 'vue-router'
import $ from 'jquery'
const router = useRouter()

export default createStore({
  state: {
    newId: '',
    newStudent: {},
    searchVal: '',
    hideConfigButton: false,
    isPinned: true,
    showConfig: false,
    sidebarType: 'bg-gradient-dark',
    isRTL: localStorage.Lang === 'ar' || !(localStorage.Lang), // Initialize RTL state based on localStorage
    color: 'success',
    isNavFixed: false,
    isAbsolute: false,
    showNavs: true,
    showSidenav: true,
    showNavbar: true,
    showFooter: true,
    showMain: true,
    isDarkMode: false,
    navbarFixed:
      'position-sticky blur shadow-blur left-auto top-1 z-index-sticky px-0 mx-4',
    absolute: 'position-absolute px-4 mx-0 w-100 z-index-2',
    transactionTypes:
    [{ id: '1413a440-d013-416c-a882-dc23fb8d52c4', name: 'استلام' },
      { id: '78d9a393-9b84-4372-bb38-5d8b42eccd29', name: 'استرداد' }
    ]
  },
  mutations: {
    setRTL (state, isRTL) {
      state.isRTL = isRTL
    },
    setNewId (state, newData) {
      state.newId = newData
    },
    setNewStudent (state, newData) {
      state.newStudent = newData
    },
    setSearchVal (state, newData) {
      state.searchVal = newData
    },
    toggleConfigurator (state) {
      state.showConfig = !state.showConfig
    },
    navbarMinimize (state) {
      const sidenavShow = document.querySelector('.g-sidenav-show')
      sidenavShow.classList.toggle('g-sidenav-pinned')
      if (window.innerWidth > 1200) {
        const appElement = document.querySelector('#app')
        if (!appElement.classList.contains('g-sidenav-pinned')) {
          $('.sidenav.fixed-end + .main-content').css('margin-right', '0px')
        } else {
          $('.sidenav.fixed-end + .main-content').css('margin-right', '17.125rem')
        }
      }
      const url = router.currentRoute.value.fullPath

      if (sidenavShow.classList.contains('g-sidenav-pinned')) {
        router.push(`${url}/pinned`)
        state.isPinned = true
      } else {
        router.push(url.replace('/pinned', ''))
        state.isPinned = false
      }
    },
    navbarFixed (state) {
      if (state.isNavFixed === false) {
        state.isNavFixed = true
      } else {
        state.isNavFixed = false
      }
    },
    toggleEveryDisplay (state) {
      state.showNavbar = !state.showNavbar
      state.showSidenav = !state.showSidenav
      state.showFooter = !state.showFooter
    },
    toggleHideConfig (state) {
      state.hideConfigButton = !state.hideConfigButton
    },
    color (state, payload) {
      state.color = payload
    }
  },
  actions: {
    setColor ({ commit }, payload) {
      commit('color', payload)
    }
  },
  getters: {}
})

<!-- eslint-disable no-new -->
<template>
  <div class="chart">
    <canvas :id="id" class="chart-canvas" :height="height"></canvas>
  </div>
</template>
<script>
import Chart from 'chart.js/auto'

export default {
  name: 'ReportsLineChart',
  props: {
    id: {
      type: String,
      default: 'line-chart'
    },
    height: {
      type: [Number, String],
      default: '170'
    },
    chart: {
      type: Object,
      required: true,
      labels: Array,
      datasets: {
        type: Object,
        label: String,
        data: Array
      }
    }
  },
  mounted () {
    var ctx = document.getElementById(this.id).getContext('2d')

    const chartStatus = Chart.getChart(this.id)
    if (chartStatus !== undefined) {
      chartStatus.destroy()
    }
    // const char=new Chart()

    // eslint-disable-next-line no-new
    new Chart(ctx, {
      type: 'line',
      data: {
        labels: this.chart.labels,
        datasets: [
          {
            label: this.chart.datasets.label,
            tension: 0,
            borderWidth: 0,
            pointRadius: 5,
            pointBackgroundColor: 'rgba(255, 255, 255, .8)',
            pointBorderColor: 'transparent',
            borderColor: 'rgba(255, 255, 255, .8)',
            // eslint-disable-next-line no-dupe-keys
            borderColor: 'rgba(255, 255, 255, .8)',
            // eslint-disable-next-line no-dupe-keys
            borderWidth: 4,
            backgroundColor: 'transparent',
            fill: true,
            data: this.chart.datasets.data,
            maxBarThickness: 6
          }
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false
          }
        },
        interaction: {
          intersect: false,
          mode: 'index'
        },
        scales: {
          y: {
            grid: {
              drawBorder: false,
              display: true,
              drawOnChartArea: true,
              drawTicks: false,
              borderDash: [5, 5],
              color: 'rgba(255, 255, 255, .2)'
            },
            ticks: {
              display: true,
              color: '#f8f9fa',
              padding: 10,
              font: {
                size: 14,
                weight: 300,
                family: 'Roboto',
                style: 'normal',
                lineHeight: 2
              }
            }
          },
          x: {
            grid: {
              drawBorder: false,
              display: false,
              drawOnChartArea: false,
              drawTicks: false,
              borderDash: [5, 5]
            },
            ticks: {
              display: true,
              color: '#f8f9fa',
              padding: 10,
              font: {
                size: 14,
                weight: 300,
                family: 'Roboto',
                style: 'normal',
                lineHeight: 2
              }
            }
          }
        }
      }
    })
  }
}
</script>
